//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
.custom-col {
  height: 60px;
  padding: 15px;
  border: 1px solid #000;
}

.rdw-editor-wrapper .rdw-editor-main {
  border: solid 1px #f1f1f1;
  padding: 0 20px;
}
